<template>
  <div data-aos="fade-in" class="contact-container" id="contact">
    <Title title="Kontakt" />
    <div class="content">
      <div class="numbers">
        <div class="title">Rezerwacja</div>
        <div class="content" v-html="data.sections.kontakt.content"/>
      </div>
      <div class="address">
        <div class="title">
          Gdzie nas znaleźć ?
        </div>
        <div class="text">
          ul. Zgoda 30<br />
          60-128 Poznań
        </div>
        <div class="map">
          <GmapMap
              :center="{lat: 52.38161736681134, lng: 16.879830965621487}"
              :zoom="18"
              map-type-id="terrain"
              style="max-width: 100%; height: 300px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
export default {
  name: "Contact",
  components: {Title},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.contact-container {
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.12);
  display: flex;
  width: 60%;
  padding: 2% 5% 5%;
  margin: 5% auto auto;
  flex-direction: column;
}
.contact-container .content {
  display: flex;
}
.contact-container .content .numbers .title {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  font-weight: 700;
  font-size: 1.75rem;
  margin-top: 10px;
  text-transform: uppercase;
}

.contact-container .content .numbers {
  display: flex;
  flex: 1;
  margin-right: 10px;
  justify-content: flex-start;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
}

.contact-container .content .numbers .content {
  display: block;
  height: fit-content;
  padding: 25px;
}

.contact-container .content .address {
  display: flex;
  flex: 1;
  margin-left: 10px;
  flex-direction: column;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.contact-container .content .address .title {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  font-weight: 700;
  font-size: 1.75rem;
  margin-top: 10px;
}

.contact-container .content .address .text {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1.25rem;
}
@media screen and (max-width: 1400px) {
  .contact-container .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .contact-container {
    width: 80%;
  }
}
</style>
