<template>
  <div data-aos="fade-in" class="treatments-container" id="treatments">
    <Title title="Zabiegi" />
    <div class="treatment-list">
      <Treatment :item="item" v-for="item in data.treatments" :key="item.id" />
    </div>
  </div>
</template>

<script>
import Title from "../Title";
import Treatment from "@/components/Treatments/Treatment";
export default {
  name: "Treatments",
  components: {Treatment, Title},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.treatments-container {
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.12);
  display: flex;
  width: 60%;
  padding: 2% 5% 5%;
  margin: 5% auto auto;
  flex-direction: column;
}
.treatments-container .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32pt;
  text-align: center;
  letter-spacing: 2px;
}
.treatment-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.treatment-list div {
  display: flex;
  flex: 0 0 40%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .treatments-container {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .treatment-list div {
    display: flex;
    flex: 0 0 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
