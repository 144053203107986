<template>
  <div class="header-row">
    <div class="social">
      <a href="https://www.facebook.com/damyansalon"><img src="../assets/fb.png" alt="Facebook" /></a>
      <a href="https://www.instagram.com/damyansalon/?hl=pl"><img src="../assets/inst.png" alt="Instagram" /></a>
    </div>
    <div class="logo">
      <Logo />
    </div>
    <div class="phones">530 037 290</div>
  </div>
</template>

<script>
import Logo from "./Logo";
export default {
  name: "Header",
  components: {Logo}
}
</script>

<style scoped>
.header-row {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: auto;
}
.header-row .social {
  padding-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.header-row .social img {
  object-fit: none;
  margin: 5px;
}
.header-row .phones {
  padding-top: 20px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-size: 10pt;
}
.header-row .logos {
  flex: 3;
}
@media screen and (max-width: 700px) {
  .header-row .social {
    display: none;
  }
  .header-row .phones {
    display: none;
  }
  .header-row .social img {
    object-fit: contain;
    margin: 5px;
  }
}
</style>
