<template>
  <div class="category-container">
    <CategorySeparator :category-name="name" />
    <table>
      <tr>
        <th></th>
        <th class="price-cell">K</th>
        <th class="price-cell">Ś</th>
        <th class="price-cell">D</th>
      </tr>
      <tr v-for="item in items" :key="item.name">
        <td class="name-cell">{{ item.name }}</td>
        <td :class="item.samePrice === true ? 'price-cell' : 'price-cell'" :colspan="item.samePrice === true ? 3: 1">{{ item.shortPrice}}</td>
        <td class="price-cell" v-if="item.samePrice === false">{{ item.mediumPrice}}</td>
        <td class="price-cell" v-if="item.samePrice === false">{{ item.longPrice}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import CategorySeparator from "./CategorySeparator";
export default {
  name: "Category",
  components: {CategorySeparator},
  props: ['name', 'items']
}
</script>

<style scoped>
.category-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
.category-container:nth-child(n+2) {
  margin-top: 40px;
}
.category-container .name-cell {
  width: 60%;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.category-container .price-cell {
  text-align: right;
  margin-right: 15px;
}
.category-container .price-cell.text-center {
  text-align: center;
}
.category-container table {
  border-spacing: 0 15px;
  overflow: scroll;
  min-width: 350px;
}
@media screen and (max-width: 1200px) {
  .category-container .name-cell {
    width: 150px;
  }
  .category-container table {
    border-spacing: 0 15px;
    overflow: scroll;
    min-width: 350px;
  }
  .category-container:nth-child(n+2) {
    margin-top: 15px;
  }
}
</style>
