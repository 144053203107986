<template>
  <div class="treatment-flex">
    <div class="treatment-padding" @click="openModal()">
      <div class="treatment-title">
        <a :href="item.url" :alt="item.title"><h3>{{ item.title }}</h3></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Treatment",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
.treatment-flex {
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.treatment-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.treatment-padding .treatment-title {
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
  font-size: 1.4rem;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
 }

.treatment-padding .treatment-title h3 {
  font-size: 1.4rem;
  margin: 0;
}

.treatment-padding .treatment-title a {
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.treatment-padding .treatment-title a h3 {
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.treatment-padding .treatment-title a:hover h3 {
  font-size: 1.55rem;
}

@media screen and (max-width: 1200px) {
  .treatment-padding .treatment-title {
    height: 50px;
  }
}

@media screen and (max-width: 800px) {
  .treatment-padding .treatment-title {
    height: 40px;
  }
}

@media screen and (max-width: 700px) {
  .treatment-padding .treatment-title {
    height: auto;
    padding: 10px;
  }
}
</style>
