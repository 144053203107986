<template>
    <div data-aos="fade-in" class="social-media-container" id="social">
      <Title title="Śledź nas na"/>
      <div class="social-media">
        <div class="facebook"><a href="https://www.facebook.com/damyansalon"><img alt="facebook" src="../assets/fb_big.png"/></a></div>
        <div class="instagram"><a href="https://www.instagram.com/damyansalon/?hl=pl"><img alt="instagram" src="../assets/ig_big.png"/></a></div>
      </div>
    </div>
</template>

<script>
import Title from "@/components/Title";
import AOS from 'aos';

export default {
  name: "SocialMedia",
  components: {Title},
  mounted() {
    AOS.refreshHard();
  }
}
</script>

<style scoped>
.social-media-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  width: 60%;
  padding: 2% 5% 5%;
  margin: 5% auto auto;
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-media div {
  margin-left: 15px;
  margin-right: 15px;
}

.social-media div img {
  max-width: 95px;
}

@media screen and (max-width: 1200px) {
  .social-media div img {
    max-width: 75px;
  }
  .social-media-container {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .social-media div img {
    max-width: 50px;
  }
}
</style>
