<template>
  <div class="right-divider-container">
    <div class="vertical-line"></div>
    <div class="horizontal-line"><hr /></div>
  </div>
</template>

<script>
export default {
  name: "RightDivider"
}
</script>

<style scoped>
.right-divider-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.right-divider-container .vertical-line {
  display: flex;
  border-right: 2px solid #000;
  height: 15px;
  margin-left: 10px;
}

.right-divider-container .horizontal-line {
  display: flex;
  width: 100%;
}

.right-divider-container .horizontal-line hr {
  width: 100%;
  margin: 0;
  border-top-width: 2px;
  border-top-color: #000;
}

</style>
