<template>
  <div class="loader">
    <img src="../assets/scissors.png" alt="Strona się wczytuje"/>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: pulse 2s infinite;
}
.loader img {
  max-width: 150px;
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
}
</style>
