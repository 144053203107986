<template>
  <div class="row">
    <div class="logos">
      <img alt="Logo" src="../assets/scissors_black.png" class="scissors">
      <img alt="Logo" src="../assets/logo_letters.png" class="letters"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "logo"
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
  width:40vw;
  align-content: space-between;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}
.logos {
  display: flex;
  flex-direction: column;
  width:100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.logos img {
  height: auto;
  display: block;
  object-fit: contain;
  max-height: 180px;
}

.logos .scissors {
  max-height: 90px;
}

.logos .letters {
  margin-top: 20px;
  max-height: 60px;
}

@media screen and (max-width: 700px) {
  .row {
    width:100%;
  }

  .logos img {
    height: auto;
    display: block;
    object-fit: contain;
    max-height: 180px;
  }
  .logo .scissors {
    max-height: 100px;
  }
  .logos .letters {
    max-height: 40px;
  }
}

</style>
