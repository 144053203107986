<template>
  <div class="category-separator-container">
    <LeftDivider />
    <div class="category">{{ categoryName }}</div>
    <RightDivider />
  </div>
</template>

<script>
import LeftDivider from "../Dividers/LeftDivider";
import RightDivider from "../Dividers/RightDivider";
export default {
  name: "CategorySeparator",
  components: {RightDivider, LeftDivider},
  props: ['categoryName']
}
</script>

<style scoped>
.category-separator-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 20px;
}
.category-separator-container .category {
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
</style>
