import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
import * as VueGoogleMaps from 'vue2-google-maps';
import AxiosPlugin from 'vue-axios-cors';

Vue.use(AxiosPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7H6-I3f8Cba1qTYO4EMtCWWl-HkGmIGY',
    libraries: 'places'
  },
})
Vue.config.productionTip = false

Vue.use(VueAxios, axios);
AOS.init();
new Vue({
  render: h => h(App),
}).$mount('#app')
