<template>
  <div id="app">
    <div v-if="isLoaded">
      <Header />
      <Menu />
      <About :data="data" />
      <Prices :data="data" />
      <Treatments :data="data"/>
      <SocialMedia />
      <Contact :data="data" />
    </div>
    <Loader v-else/>
  </div>
</template>

<script>
import Prices from "./components/Prices/Prices";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Contact from "./components/Contact";
import About from "./components/About";
import SocialMedia from "@/components/SocialMedia";
import Loader from "@/components/Loader";
import axios from "axios";
import Treatments from "./components/Treatments/Treatments";
export default {
  name: 'App',
  components: {
    Treatments,
    Loader,
    SocialMedia,
    About,
    Contact,
    Menu,
    Header,
    Prices,
  },
  data: function () {
    return {
      isLoaded: false,
      data: {}
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_BASE_URL + "/api/data", {
      headers: {
        // remove headers
      }
    })
    .then((response) => {
      setTimeout(() => {
        this.isLoaded = true;
        this.data = response.data;
      }, 500);
    })
  }
}
</script>

<style>
body {
  background: #EEEEEE;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
}
#app {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-height: 100vh;
}
@media screen and (max-width: 800px) {
  html {
    font-size: 11px;
  }
}
</style>
