<template>
  <div class="title-container">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ['title']
}
</script>

<style scoped>
.title-container {
  display: flex;
  margin: 20px auto;
}
.title-container .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24pt;
  text-align: center;
  letter-spacing: 2px;
}
</style>
