<template>
  <div data-aos="fade-in" class="prices-container" id="prices">
    <Title title="Cennik" />
    <Category :name="item.name" :items="item.items" v-for="item in data.services" :key="item.name"/>
  </div>
</template>

<script>
import Category from "./Category";
import Title from "@/components/Title";
export default {
  name: "Prices",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {Title, Category},
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.prices-container {
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.12);
  display: flex;
  width: 60%;
  padding: 2% 5% 5%;
  margin: 5% auto auto;
  flex-direction: column;
}
.prices-container .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32pt;
  text-align: center;
  letter-spacing: 2px;
}
@media screen and (max-width: 1200px) {
  .prices-container {
    width: 80%;
  }
}
</style>
