<template>
  <div class="row-menu">
    <div><a href="#about">DAMYAN</a></div>
    <div><a href="#prices">Cennik</a></div>
    <div><a href="#treatments">Zabiegi</a></div>
    <div><a href="#social">Social media</a></div>
    <div><a href="#contact">Kontakt</a></div>
  </div>
</template>

<script>
export default {
  name: "Menu"
}
</script>

<style scoped>
.row-menu {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.row-menu div {
  margin: 10px;
  transition: all 0.3s;
  padding: 15px;
}

.row-menu div:hover {
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}


.row-menu div a {
  color: #000;
  text-decoration: none;
}

.row-menu div a:hover {
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .row-menu {
    flex-wrap: wrap;
  }
  .row-menu div {
    margin: 3px;
    transition: all .3s;
    padding: 5px;
    width: 25%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
</style>
