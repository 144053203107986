<template>
  <div class="about-container" id="about">
    <div class="column-container">
      <div class="row-container">
        <div class="image">
          <img src="../assets/about.jpg" alt="Damyan salon" />
        </div>
        <div class="content" v-html="data.sections.damyan.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.about-container {
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  width: 60%;
  padding: 2% 5% 5%;
  margin: 30px auto auto;
  flex-direction: column;
}

.about-container .column-container {
  display: flex;
  flex-direction: column;
}

.about-container .row-container {
  display: flex;
  flex-direction: row;
}

.about-container .row-container .image {
  display: flex;
  flex: 1;
  margin-right: 2.5%;
}

.about-container .row-container .image img {
  display: flex;
  object-fit: contain;
  max-width: 100%;
  height: auto
}

.about-container .row-container .content {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 2.5%;
  padding: 15px;
}

.about-container .row-container .content p {
  margin-top: 7px;
  margin-bottom: 7px;
  text-align: center;
}

.about-container .row-container .content h1 {
  display: inline;
  font-size: 1.3rem;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  .about-container .row-container {
    flex-direction: column;
  }

  .about-container .row-container .image {
    display: flex;
    flex: 1;
    margin-right: 2.5%;
    justify-content: center;
  }

  .about-container .row-container .image img {
    display: flex;
    object-fit: contain;
    max-width: 50%;
    height: auto;
  }

  .about-container .row-container .content {
    display: flex;
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .about-container {
    width: 80%;
  }
}
</style>
